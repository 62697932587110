import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { PaginatorModule, Paginator } from 'primeng/paginator';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache, CachedQueryToken } from '@ripple/services';
import { EntityTypes, Organization, Team } from '../../../_core/models';
import { TeamService, OrganizationService } from '../../../_core/services';
import { environment } from '@ripple/environment';
import { EntityFilter, FilterOperator } from '@ripple/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sagesse-team-organizations',
  templateUrl: './team-organizations.component.html',
  styleUrls: ['./team-organizations.component.scss']
})
export class TeamOrganizationsComponent implements OnInit, OnChanges {

  @Input() team: Team;
  @ViewChild('myPaginator', {static: false}) paginator: Paginator;

  organizationServiceCache: WarpEntityServiceCache<Organization>;

  organizationService: OrganizationService;
  organizations: Organization[] = [];
  organizationsFiltered: Organization[] = [];
  organizationSearch = '';
  filterTimeout;
  organizationLoading = true;
  addingOrDeletingDialogShow = false;
  addingOrDeleting: 'Adding' | 'Removing';

  pageFilter: EntityFilter = EntityFilter.None;
  pageLoading = true;
  pageSub: Subscription;
  queryTokenSub: Subscription;
  queryToken: CachedQueryToken<Organization>;

  first = 0;
  pageSize = 100;
  pageTotal = 0;
  currPage = 0;

  get allowNonMemberOrganizations() {
    const ids = environment['allowNonMemberOrgTeamIds'] ? environment['allowNonMemberOrgTeamIds'] : [];
    return ids.includes(this.team.entityId);
  }

  public teamService: TeamService;

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService,
  ) {
    this.organizationServiceCache = this.warpEntityFactory.get(EntityTypes.Organization) as WarpEntityServiceCache<Organization>;
    this.organizationService = this.warpEntityFactory.get(EntityTypes.Organization) as OrganizationService
    this.teamService = this.warpEntityFactory.get(EntityTypes.Team) as TeamService;
  }

  ngOnInit(): void {
    // 9999999
    // this.organizationService.initQuery(EntityFilter.None, 9999999).maintainOnUpdate().getPage(0).subscribe(organizations => {
    //   this.organizations = organizations;
    //   this.organizationsFiltered = organizations;
    //   this.filterOrganizations();
    //   this.updateReference();
    //   this.organizationLoading = false;
    // });

    /*
    REMOVED this since it only loaded organizations that are associated with the team already or have been associated in the past.
    Did not allow new organizations to be added to the team
    let allOrganizations = [];
    if (this.team.properties.organization instanceof Array) {
      allOrganizations = [...allOrganizations, ...this.team.properties.organization];
    }
    if (this.team.properties.nonmemberorganization instanceof Array) {
      allOrganizations = [...allOrganizations, ...this.team.properties.nonmemberorganization];
    }
    const organizationIds = allOrganizations.map(o => o.id).join(',');
    this.organizationService.getTeamOrganizations(this.team.entityId, organizationIds, '0').subscribe(organizations => {
      this.organizations = organizations;
      this.organizationsFiltered = organizations;
      this.filterOrganizations();
      this.updateReference();
      this.organizationLoading = false;
    });
    */

    this.loadAndPaginate(null);
  }

  ngOnChanges() {
    this.updateReference();
  }

  updateReference() {
    if (this.team.properties.organization && this.team.properties.organization instanceof Array)
      this.team.properties.organization = this.team.properties.organization
        .map(o => this.organizations.find(or => or.entityId === o.id) ? this.organizations.find(or => or.entityId === o.id) : o);
    if (this.team.properties.nonmemberorganization && this.team.properties.nonmemberorganization instanceof Array)
      this.team.properties.nonmemberorganization = this.team.properties.nonmemberorganization
        .map(o => this.organizations.find(or => or.entityId === o.id) ? this.organizations.find(or => or.entityId === o.id) : o);
  }

  filterOrganizations(now: boolean = false) {
    if (this.filterTimeout) clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(
      () => {
        this.loadAndPaginate(null);
      }, 800);
  }

  /*
  filterOrganizations(now: boolean = false) {
    if (this.filterTimeout) clearTimeout(this.filterTimeout);

    if (now) {
      this.organizationsFiltered = this.organizations
        .filter(o => o.name.toLowerCase().includes(this.organizationSearch.toLowerCase()));
      this.sortOrganizations();
    } else {
      const that = this;

      this.filterTimeout = setTimeout(
        () => {
          this.organizationsFiltered = this.organizations
            .filter(o => o.name.toLowerCase().includes(this.organizationSearch.toLowerCase()));
          this.sortOrganizations();
        },
        800
      );
    }
  }
  */

  loadAndPaginate(event) {
    let page = (event && event.page) ?? 0;
    this.currPage = page;
    
    const searchValue = this.organizationSearch.toLowerCase();
    const searchValFilter = EntityFilter.AdvancedUnion(
      [`name`]
        .map(key => ({
          key,
          operator: FilterOperator.Like,
          value: searchValue
        }))
    );
    
    let filter = searchValFilter.orderBy('name', 'asc');
    this.queryToken = this.organizationServiceCache.initQuery(filter, this.pageSize);
    
    if (this.queryTokenSub) this.queryTokenSub.unsubscribe();
    this.queryTokenSub = this.queryToken.totalForFilter.subscribe((total) => {
      this.pageTotal = total;
    });

    this.organizations = [];
    if (this.pageSub) this.pageSub.unsubscribe();

    this.pageLoading = true;
    this.pageSub = this.queryToken.getPage(page).subscribe(orgs => {
      this.organizations = orgs;
      this.updateReference();
      this.pageLoading = false;
    });
  }

  /*
  REMOVED 2024-10-07 in favor of using Pagination + EntityFilters
  sortOrganizations() {
    this.organizationsFiltered.sort((a, b) => {
      let aIsChecked = -1;
      let bIsChecked = -1;
      if (this.team.properties.organization && this.team.properties.organization.find(c => c.id === a.entityId)) {
        aIsChecked = 2;
      } else if (this.team.properties.nonmemberorganization &&
        this.team.properties.nonmemberorganization.find(c => c.id === a.entityId)) {
        aIsChecked = 1;
      }

      if (this.team.properties.organization && this.team.properties.organization.find(c => c.id === b.entityId)) {
        bIsChecked = 2;
      } else if (this.team.properties.nonmemberorganization &&
        this.team.properties.nonmemberorganization.find(c => c.id === b.entityId)) {
        bIsChecked = 1;
      }
      if (aIsChecked === bIsChecked) {
        return a.name.localeCompare(b.name);
      }
      return bIsChecked - aIsChecked;
    });
  }
  */

  onChange(event, organization,  partOfTeam = true) {
    if (event.checked) {
      this.addingOrDeleting = 'Adding';
      // if (partOfTeam && this.team.properties.nonmemberorganization) {
      //   this.team.properties.nonmemberorganization =
      //     this.team.properties.nonmemberorganization.filter(o => o.entityId !== organization.entityId);
      // }
      // if (!partOfTeam && this.team.properties.organization) {
      //   this.team.properties.organization =
      //     this.team.properties.organization.filter(o => o.entityId !== organization.entityId);
      // }
    } else {
      this.addingOrDeleting = 'Removing';
      if (partOfTeam && this.team.properties.nonmemberorganization) {
        this.team.properties.nonmemberorganization =
          this.team.properties.nonmemberorganization.filter(o => o.entityId !== organization.entityId);
      }
    }
    this.addingOrDeletingDialogShow = true;
    this.teamService.updateSinglePropertyOnly(this.team, 'organization').subscribe(result1 => {
      console.log('update organization', result1);
      this.teamService.updateSinglePropertyOnly(this.team, 'nonmemberorganization').subscribe(result2 => {
        console.log('update non member organization', result2);
        this.addingOrDeletingDialogShow = false;
        //this.sortOrganizations();
      })
    });
  }

  disableNonMember(org) {
    if (!this.team.properties.organization) {
      return true;
    } else {
      const ids = this.team.properties.organization.map(o => o.id);
      return !ids.includes(org.id);
    }
  }

}
